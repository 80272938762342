// Copyright 2023 Lou Amadio
//
// robotSelectionScene.ts is part of dero_web. It Implements the robot selection screen.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import EventEmitter from "events";

import * as BABYLON from 'babylonjs';
import * as Materials from 'babylonjs-materials';
import * as GUI from 'babylonjs-gui';

type Robot = {
  id: string;
  name: string;
  type: string;
  description: string;
};


export class RobotSelectionScene extends EventEmitter {
  private robotSelectionTexture : BABYLON.Nullable<GUI.AdvancedDynamicTexture> = null;
  private robotSelectionGUI : BABYLON.Nullable<GUI.AdvancedDynamicTexture> = null;
  private robotList : BABYLON.Nullable<GUI.StackPanel> = null;

  private robots : Robot[] = [];

  'connect': ( robotId : string) => void;

  public async createScene(engine: BABYLON.Engine, canvas: HTMLCanvasElement): Promise<BABYLON.Scene> {
    let scene = new BABYLON.Scene(engine);
    var camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), scene);
    camera.setTarget(BABYLON.Vector3.Zero());
    var light = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 1, 0), scene);
    
    // get the list from local storage
    let robotListJson = localStorage.getItem('robotList');
    if (robotListJson != null) {
      try {
      this.robots = JSON.parse(robotListJson);
      } catch (e) {
        console.log("Error parsing robot list: " + e);
        this.robots = [];
      }
    }

    // don't clear what's behind
    scene.autoClear = false;

    this.robotSelectionTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("RobotSelectionGUI", true, scene);
    if (this.robotSelectionTexture == null) {
        console.log("Error: Unable to robot Selection GUI");
        return scene;
    }

    // Load from the server if running on a robot
    //this.robotSelectionGUI = await this.robotSelectionTexture.parseFromURLAsync("assets/robot_selection_screen.json", false);

    // load from snippet if running locally
    this.robotSelectionGUI = await this.robotSelectionTexture.parseFromSnippetAsync("F490A9#13", false);
    if (this.robotSelectionGUI == null) {
        console.log("Error: Unable to robot Selection GUI");
        return scene;
    }

    let that = this;

    let addRobotDialog = <GUI.Grid>this.robotSelectionGUI.getControlByName("AddRobotPanel");
    if (addRobotDialog == null) {
        console.log("Error: Unable to find add robot dialog");
        return scene;
    }

    addRobotDialog.isVisible = false;

    // get the robot name and id input fields
    let robotName = <GUI.InputText>this.robotSelectionGUI.getControlByName("FriendlyNameInput");
    if (robotName == null) {
        console.log("Error: Unable to find robot name input");
        return scene;
    }

    let robotId = <GUI.InputText>this.robotSelectionGUI.getControlByName("PeerIdInput");
    if (robotId == null) {
        console.log("Error: Unable to find robot id input");
        return scene;
    }

    // handle the add robot dialog cancel button
    let addRobotCancelButton = <GUI.Button>this.robotSelectionGUI.getControlByName("AddRobotCancelButton");
    if (addRobotCancelButton == null) {
        console.log("Error: Unable to find add robot cancel Button");
        return scene;
    }

    addRobotCancelButton.onPointerClickObservable.add(async function () {
      console.log("Add Robot Cancel Button Clicked");

      addRobotDialog.isVisible = false;
      robotListPanel.isVisible = true;

      // clear the input fields
      robotName.text = "";
      robotId.text = "";
    });

    let robotListPanel = <GUI.Grid>this.robotSelectionGUI.getControlByName("RobotListPanel");
    if (robotListPanel == null) {
        console.log("Error: Unable to find robot list panel");
        return scene;
    }

    robotListPanel.isVisible = true;

    // Process Add Robot Button
    let addRobotButton = <GUI.Button>this.robotSelectionGUI.getControlByName("AddButton");
    if (addRobotButton == null) {
        console.log("Error: Unable to find add robot Button");
        return scene;
    }

    addRobotButton.onPointerClickObservable.add(async function () {
      console.log("Add Robot Button Clicked");

      // Display the add robot dialog
      robotListPanel.isVisible = false;
      addRobotDialog.isVisible = true;
    });

    this.robotList = <GUI.StackPanel>this.robotSelectionGUI.getControlByName("RobotList");
    if (this.robotList == null) {
        console.log("Error: Unable to find robot list");
        return scene;
    }

    // Process Remove Robot
    let removeRobotButton = <GUI.Button>this.robotSelectionGUI.getControlByName("RemoveButton");
    if (removeRobotButton == null) {
        console.log("Error: Unable to find remove robot Button");
        return scene;
    }

    removeRobotButton.onPointerClickObservable.add(async function () {
      console.log("Remove Robot Button Clicked");

      if (that.robotList == null) {
        console.log("Error: Unable to find robot list");
        return;
      }

      let selectedRobot : GUI.TextBlock | null = await that.findSelectedRobot();
      if (selectedRobot == null) {
        console.log("Error: No robot selected");
        return;
      }

      for (let i = 0; i < that.robotList.children.length; i++) {
        let child = that.robotList.children[i];
        if (child instanceof GUI.TextBlock) {
          if (child.color == "green") {
            // find robot by id in this.robotList
            for (let j = 0; j < that.robots.length; j++) {
              let robot = that.robots[j];
              if (robot.id == child._customData) {
                that.robots.splice(j, 1);
                break;
              }
            }

            child.dispose();

            localStorage.setItem('robotList', JSON.stringify(that.robotList));

            break;
          }
        }
      }
    });

    // handle the add robot dialog add button
    let addRobotAddButton = <GUI.Button>this.robotSelectionGUI.getControlByName("AddRobotAddButton");
    if (addRobotAddButton == null) {
        console.log("Error: Unable to find add robot add Button");
        return scene;
    }

    addRobotAddButton.onPointerClickObservable.add(async function () {
      console.log("Add Robot Add Button Clicked");

      // add the robot to the list
      let robot = {
        id: robotId.text,
        name: robotName.text,
        type: "robot",
        description: ""
      };

      that.robots.push(robot);

      // save the list to local storage
      localStorage.setItem('robotList', JSON.stringify(that.robots));

      // update the robot list
      that.populateRobotList();

      addRobotDialog.isVisible = false;
      robotListPanel.isVisible = true;

      // clear the input fields
      robotName.text = "";
      robotId.text = "";
    });

    // handle connect button
    let connectButton = <GUI.Button>this.robotSelectionGUI.getControlByName("ConnectButton");
    if (connectButton == null) {
        console.log("Error: Unable to find connect Button");
        return scene;
    }

    connectButton.onPointerClickObservable.add(async function () {
      console.log("Connect Button Clicked");

      let selectedRobot : GUI.TextBlock | null = await that.findSelectedRobot();

      if (selectedRobot == null) {
        console.log("Error: No robot selected");
        return;
      }

      // find robot by id in this.robotList
      for (let i = 0; i < that.robots.length; i++) {
        let robot = that.robots[i];
        if (robot.id == selectedRobot._customData) {
          // connect to the robot
          console.log("Connecting to robot: " + robot.id);

          that.emit('connect', robot.id);
        }
      }
    });

    this.populateRobotList();

    return scene;
  }

  private async findSelectedRobot() : Promise<GUI.TextBlock | null> {

    if (this.robotList == null) {
      console.log("Error: Unable to find robot list");
      return null;
    }
    
    let selectedRobot : GUI.TextBlock | null = null;
    for (let i = 0; i < this.robotList.children.length; i++) {
      let child = this.robotList.children[i];
      if (child instanceof GUI.TextBlock) {
        if (child.color == "green") {
          selectedRobot = child;
          break;
        }
      }
    }

    return selectedRobot;
  }


  // populate the robot list from the json blob stored in local storage
  private populateRobotList() {
    if (this.robotList == null) {
      console.log("Error: Unable to find robot list");
      return;
    }

    // clear the list
    this.robotList.clearControls();

    // add each robot to the list
    for (let i = 0; i < this.robots.length; i++) {
      let robot = this.robots[i];

      let robotItem = new GUI.TextBlock();
      robotItem.text = robot.name;
      robotItem.width = "100%";
      robotItem.height = "40px";
      robotItem.color = "white";
      robotItem.fontSize = 24;
      robotItem._customData = robot.id;
      robotItem.onPointerClickObservable.add(async function () {
        console.log("Robot Item Clicked: " + robotItem._customData);

        robotItem.color = "green";

      });

      this.robotList.addControl(robotItem);
    }
  }
}
