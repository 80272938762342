// Copyright 2023 Lou Amadio
//
// remoteControl.ts is part of dero_web. It Implements the remote control screen.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.


import * as BABYLON from 'babylonjs';
import * as Materials from 'babylonjs-materials';
import * as GUI from 'babylonjs-gui';
import {currentROSConnection} from './dero_web';
import type { ROSConnection } from '@polyhobbyist/ros2ts'
import { Subscriber } from '@polyhobbyist/ros2ts';
import { Publisher } from '@polyhobbyist/ros2ts';

export class RemoteControlScene {
    private aDown : boolean = false;
    private bDown : boolean = false;
    private xDown : boolean  = false;
    private yDown : boolean  = false;
    private leftPuckDown : boolean = false;
    private left_puck : BABYLON.Nullable<GUI.Control> = null;
    private video_container : BABYLON.Nullable<GUI.Control> = null;
    private updateTimer : NodeJS.Timeout | null = null;

    private connection : ROSConnection | null = null;

    private joyPub : Publisher<any> | null = null;
    private imageSub : Subscriber<any> | null = null;
    
    
    private xAddPos = 0;
    private yAddPos = 0;
    
    private leftJoystick = null;
    private virtualJoystickTexture : BABYLON.Nullable<GUI.AdvancedDynamicTexture> = null;
    private joystickGUI : BABYLON.Nullable<GUI.AdvancedDynamicTexture> = null;
  

    constructor() {
    }

    public enter() {
      // Enter gets called by the main render loop when the scene is entered, so that it can start up any watchers, publishers or timers

      let that = this;

      this.joyPub = new Publisher<any>(currentROSConnection!, "sensor_msgs/Joy", "/joy", false, 1);
      this.joyPub.advertise();

      this.updateTimer = setInterval(function () {
        const [gp] = navigator.getGamepads();

        // Note: with the gamepad API, the directions opposite than ROS expectations. 
        // Axes need to be inverted, hence the negative.

        if (gp != null) {
            var leftTrigger = 0.0;
            if (gp.buttons[6].pressed) {
                leftTrigger = 1.0;
            }

            var rightTrigger = 0.0;
            if (gp.buttons[6].pressed) {
                rightTrigger = 1.0;
            }

            var joyMsg = {
                axes: [-gp.axes[0], -gp.axes[1], leftTrigger, gp.axes[2], gp.axes[3], rightTrigger],
                buttons: [gp.buttons[0].pressed, gp.buttons[1].pressed, gp.buttons[2].pressed, gp.buttons[3].pressed, gp.buttons[4].pressed, gp.buttons[5].pressed, gp.buttons[8].pressed, gp.buttons[9].pressed, gp.buttons[10].pressed, gp.buttons[11].pressed]
            };

            that.joyPub?.publish(joyMsg);
        } else {
            var left_LeftRight = that.xAddPos;
            var left_UpDown = that.yAddPos;
            var hatPressed = false;
            if (that.left_puck != null && that.leftPuckDown) {
              hatPressed = true;
            }

            var joyMsg = {
              axes: [-left_LeftRight, -left_UpDown, 0.0, 0.0, 0.0, 0.0],
              buttons: [that.aDown, that.bDown, that.xDown, that.yDown, false, false, false, false, hatPressed, false]
            };

            that.joyPub?.publish(joyMsg);

        }
      }, 20);
    }

    public leave() {
      // Leave gets called by the main render loop when the scene is left, so that it can stop any watchers, publishers or timers
      if (this.updateTimer != null) {
        clearInterval(this.updateTimer);
        this.updateTimer = null;
      }

      this.joyPub?.unadvertise();
      this.joyPub = null;
    }

    /*
    private async loadWebRTC() {
        // We're loading the helper library from a different protocol and different port
        const scriptElement = document.createElement('script');
        const webrtcScript = window.location.protocol + "//" + window.location.hostname + ":8080/webrtc_ros.js";
        console.log("Dynamically loading WebRTC_ros.js from " + webrtcScript);
        scriptElement.setAttribute('type', "text/javascript");
        scriptElement.setAttribute('src', webrtcScript);
        document.head.appendChild(scriptElement);

        scriptElement.onload = scriptElement.onreadystatechange = function()
        {
          try
          {
            // from webrtc_ros
            console.log("Establishing WebRTC connection");
            let conn = WebrtcRos.createConnection((location.protocol === 'https:' ? 'wss://' : 'ws://') +window.location.hostname+":8080/webrtc");
            conn.onConfigurationNeeded = function()
            {
              console.log("Requesting WebRTC video subscription");
              let config = {};
              config.video = {"id": "subscribed_video", "src": "ros_image:/image_raw"};
              conn.addRemoteStream(config).then(function(event) {
                console.log("Connecting WebRTC stream to BabylonJS video element");
                var videoElement = document.getElementById("remote-video");
                videoElement.srcObject = event.stream;

                var planeOpts = {
                    height: video_container._currentMeasure.height, 
                    width: video_container._currentMeasure.width, 
                    sideOrientation: BABYLON.Mesh.DOUBLESIDE
                };              
                var videoPlane = BABYLON.MeshBuilder.CreatePlane("videoPlane", planeOpts, scene);
                var vidPos = (new BABYLON.Vector3(0,0,0.1))
                videoPlane.position = vidPos;
                var videoPlaneMat = new BABYLON.StandardMaterial("m", scene);
                var videoPlaneVidTex = new BABYLON.VideoTexture("vidtex",videoElement, scene);
                videoPlaneMat.diffuseTexture = videoPlaneVidTex;
                videoPlaneMat.roughness = 1;
                videoPlaneMat.emissiveColor = new BABYLON.Color3.White();
                videoPlane.material = videoPlaneMat;

                event.remove.then(function(event) {
                  console.log("Disconnecting WebRTC stream from <video> element");
                  document.getElementById("remote-video").srcObject = null;
                });
              });
              conn.sendConfigure();
            }
            conn.connect();

          }
          catch (err)
          {
            console.log("Could not create WebRTC: " + err);

          }
        }
    }
    */

    public setCurrentRobotConnection(connection: ROSConnection) {
      this.connection = connection;

      this.joyPub = null;
      this.imageSub = null;

      if (this.connection != null) {
        this.joyPub = connection.create_publisher<any>('sensor_msgs/Joy', 'joy');
        this.imageSub = connection.create_subscription<any>('sensor_msgs/Image', 'image_raw', (msg) => {
          console.log("Got image");
          
        });
      }
    }

    // Create teleop scene
    public async createScene(engine: BABYLON.Engine, canvas: HTMLCanvasElement): Promise<BABYLON.Scene> {
      let scene = new BABYLON.Scene(engine);
      var camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), scene);
      camera.setTarget(BABYLON.Vector3.Zero());
      var light = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 1, 0), scene);

      this.virtualJoystickTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("GUI", true, scene);
      if (this.virtualJoystickTexture == null) {
          console.log("Error: Unable to create GUI");
          return scene;
      }

      // Load from the server if running on a robot
      //this.joystickGUI = await this.virtualJoystickTexture.parseFromURLAsync("assets/joystick_gui.json", false);

      //or for bringup, open directly in web browser and use the gui editor to load the UI.
      this.joystickGUI = await this.virtualJoystickTexture.parseFromSnippetAsync("2A4YES#4", false);

      this.video_container = this.virtualJoystickTexture.getControlByName("video_container");

      //this.loadWebRTC();

      this.left_puck = this.virtualJoystickTexture.getControlByName("Left_Puck") ;
      let left_thumb = this.virtualJoystickTexture.getControlByName("Left_ThumbContainer");
      let left_inner_thumb = this.virtualJoystickTexture.getControlByName("Left_InnerThumb");
      if (this.left_puck == null || left_thumb == null || left_inner_thumb == null) {
          console.log("Error: Unable to find joystick controls");
          return scene; 
      }

      left_inner_thumb.isPointerBlocker = false;
      left_thumb.isPointerBlocker = true;
      this.left_puck.isPointerBlocker = false;

      this.left_puck.isVisible = false;

      let that = this;

      left_thumb.onPointerDownObservable.add(function(coordinates) {
          if (left_thumb == null || that.left_puck == null) {
              return;
          }

          let transformCoordinates = left_thumb.getLocalCoordinates(coordinates)
          that.left_puck.isVisible = true;
          that.left_puck.left = that.left_puck.left = transformCoordinates.x - left_thumb._currentMeasure.left - left_thumb._currentMeasure.width * that.left_puck.transformCenterX;
          that.left_puck.top = that.left_puck.top = transformCoordinates.y - left_thumb._currentMeasure.top - left_thumb._currentMeasure.height * that.left_puck.transformCenterY;
          that.leftPuckDown = true;
          left_thumb.alpha = 0.9;
      });

      left_thumb.onPointerUpObservable.add(function(coordinates) {
          if (left_thumb == null || that.left_puck == null) {
              return;
          }

          that.xAddPos = 0;
          that.yAddPos = 0;
          that.leftPuckDown = false;
          that.left_puck.isVisible = false;
          left_thumb.alpha = 0.4;
      });

      left_thumb.onPointerMoveObservable.add(function(coordinates) {
          if (left_thumb == null || that.left_puck == null) {
              return;
          }
          if (that.leftPuckDown) {
            that.left_puck.left = that.left_puck.left = coordinates.x - left_thumb._currentMeasure.left - left_thumb._currentMeasure.width * that.left_puck.transformCenterX;
            that.left_puck.top = that.left_puck.top = coordinates.y - left_thumb._currentMeasure.top - left_thumb._currentMeasure.height * that.left_puck.transformCenterY;

            that.xAddPos = that.left_puck.left / (left_thumb._currentMeasure.width / 2);
            that.yAddPos = that.left_puck.top / (left_thumb._currentMeasure.height / 2);
            }
      });

      let a = this.virtualJoystickTexture.getControlByName("A_Button");
      let b = this.virtualJoystickTexture.getControlByName("B_Button");
      let x = this.virtualJoystickTexture.getControlByName("X_Button");
      let y = this.virtualJoystickTexture.getControlByName("Y_Button");
      if (a == null || b == null || x == null || y == null) {
          console.log("Error: Unable to find button controls");
          return scene;
      }

      
      a.onPointerDownObservable.add(() => {that.aDown = true});        
      a.onPointerUpObservable.add(() => {that.aDown = false});        

      b.onPointerDownObservable.add(() => {that.bDown = true});        
      b.onPointerUpObservable.add(() => {that.bDown = false});        

      x.onPointerDownObservable.add(() => {that.xDown = true});        
      x.onPointerUpObservable.add(() => {that.xDown = false});        

      y.onPointerDownObservable.add(() => {that.yDown = true});        
      y.onPointerUpObservable.add(() => {that.yDown = false});

    
      window.addEventListener("gamepadconnected", (event) => {
          // if we have a gamepad, disable the screen controller
          // TODO: loadedGUI._canvas.style.visibility = "hidden";

          console.log("Gamepad connected at index %d: %s. %d buttons, %d axes.",
              event.gamepad.index, event.gamepad.id,
              event.gamepad.buttons.length, event.gamepad.axes.length);
      });

      window.addEventListener("gamepaddisconnected", (event) => {
          // if we don't have a gamepad, enable the screen controller
          // TODO loadedGUI._canvas.style.visibility = "visible";
      });

      return scene;
    }
}
