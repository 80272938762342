// Copyright 2023 Lou Amadio
//
// loginScene.ts is part of dero_web. It Implements the login screen.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import EventEmitter from "events";

import * as BABYLON from 'babylonjs';
import * as Materials from 'babylonjs-materials';
import * as GUI from 'babylonjs-gui';
import { createFromJSON, createRSAPeerId } from '@libp2p/peer-id-factory'
import { toString as uint8ArrayToString } from 'uint8arrays/to-string'
import type { PeerId } from '@libp2p/interface/peer-id'


export class LoginScene extends EventEmitter {
  private loginTexture : BABYLON.Nullable<GUI.AdvancedDynamicTexture> = null;
  private loginGUI : BABYLON.Nullable<GUI.AdvancedDynamicTexture> = null;
  private id : BABYLON.Nullable<GUI.InputText> = null;
  private pub : BABYLON.Nullable<GUI.InputText> = null;
  private priv : BABYLON.Nullable<GUI.InputText> = null;


  'authenticated': ( u : PeerId) => void;

    public async createScene(engine: BABYLON.Engine, canvas: HTMLCanvasElement): Promise<BABYLON.Scene> {
      let scene = new BABYLON.Scene(engine);
      var camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), scene);
      camera.setTarget(BABYLON.Vector3.Zero());
      var light = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 1, 0), scene);        
  
      // don't clear what's behind
      scene.autoClear = false;

      this.loginTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("LoginGUI", true, scene);
      if (this.loginTexture == null) {
          console.log("Error: Unable to login GUI");
          return scene;
      }

      // Load from the server if running on a robot
      //this.loginGUI = await this.loginTexture.parseFromURLAsync("assets/login_screen.json", false);

      // load from snippet if running locally
      this.loginGUI = await this.loginTexture.parseFromSnippetAsync("F490A9#14", false);

      if (this.loginGUI == null) {
          console.log("Error: Unable to login GUI");
          return scene;
      }

      let authenticateButton = <GUI.Button>this.loginGUI.getControlByName("AuthenticateButton");
      if (authenticateButton == null) {
          console.log("Error: Unable to find authenticate Button");
          return scene;
      }
      this.id = <GUI.InputText>this.loginGUI.getControlByName("Multihash_input");
      this.pub = <GUI.InputText>this.loginGUI.getControlByName("PublicKey_input");
      this.priv = <GUI.InputText>this.loginGUI.getControlByName("PrivateKey_input");

      let that = this;
      let createButton = <GUI.Button>this.loginGUI.getControlByName("CreateButton");
      if (createButton == null) {
          console.log("Error: Unable to find create Button");
          return scene;
      }

      createButton.onPointerClickObservable.add(async function () {
        if (that.id == null || that.pub == null || that.priv == null) {
          console.log("Error: Unable to find login information");
          return;
        }

        const peerId  = await createRSAPeerId();
        let id = uint8ArrayToString(peerId.multihash.bytes, 'base58btc');
        let privateKey = "";
        let publicKey = "";
        if (peerId.publicKey) {
          publicKey = uint8ArrayToString(peerId.publicKey, 'base64pad');
        }
        if (peerId.privateKey) {
          privateKey = uint8ArrayToString(peerId.privateKey, 'base64pad');
        }

        that.pub.text = publicKey;
        that.priv.text = privateKey;
        that.id.text = id;

        // save to file
        let u = {id: id, pubKey: publicKey, privKey: privateKey}
        let data = JSON.stringify(u);
        let a = document.createElement('a');
        let file = new Blob([data], {type: 'text/plain'});
        a.href = URL.createObjectURL(file);
        a.download = 'user.json';
        a.click();

        alert("Please save the file to a safe location. You can use the load button to use this later. To connect to a robot, provide the Id to an administrator of that robot.");
      });

      let loadButton = <GUI.Button>this.loginGUI.getControlByName("LoadButton");
      if (loadButton == null) {
          console.log("Error: Unable to find load Button");
          return scene;
      }

      loadButton.onPointerClickObservable.add(function () {
        // Open Local File Dialog
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = e => {
          if (e.target == null || !(e.target instanceof HTMLInputElement)) {
            console.log("Error: Unable to read file");
            return;
          } 

          var target = e.target as HTMLInputElement;

          if (target.files == null || target.files.length == 0) {
            console.log("Error: Unable to read file");
            return;
          }

          // getting a hold of the file reference
          var file = target.files[0]; 

          // setting up the reader
          var reader = new FileReader();
          reader.readAsText(file,'UTF-8');

          // here we tell the reader what to do when it's done reading...
          reader.onload = readerEvent => {
            var content = readerEvent.target?.result;
            if (content == null) {
              console.log("Error: Unable to read file");
              return;
            }

            if (that.id == null || that.pub == null || that.priv == null) {
              console.log("Error: Unable to find login information");
              return;
            }

            let u = JSON.parse(content as string);

            if (u.id == null || u.pubKey == null || u.privKey == null || u.id == "" || u.pubKey == "" || u.privKey == "") {
              console.log("Error: Unable to find login information");
              alert("The file you selected is not a valid user file");
              return;
            }

            that.pub.text = u.pubKey;
            that.priv.text = u.privKey;
            that.id.text = u.id;
          }
        }

        input.click();
      });


      authenticateButton.onPointerClickObservable.add(async function () {
        if (that.id == null || that.pub == null || that.priv == null || 
          that.id.text == "" || that.pub.text == "" || that.priv.text == "") {

          alert("Please fill in all login fields");
          return;
        }

        let multihash = that.id.text;
        let publicKey = that.pub.text;
        let privateKey = that.priv.text;
        let u = {id: multihash, pubKey: publicKey, privKey: privateKey};

        try {
          let peerId = await createFromJSON(u);
          
          that.emit("authenticated", peerId);
        } catch (e) {
          alert("This does not appear to be valid user information: " + e);
        }


      });      

      return scene;
    }
}
