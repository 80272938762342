// Copyright 2023 Lou Amadio
//
// dero_web.ts is part of dero_web.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.


import * as BABYLON from 'babylonjs';
import * as Materials from 'babylonjs-materials';
import * as GUI from 'babylonjs-gui';
import { BackgroundScene } from './backgroundScene';
import { LoginScene } from './loginScene';
import { RobotSelectionScene } from './robotSelectionScene';
import { RemoteControlScene } from './remoteControl';
import {ROSConnection} from '@polyhobbyist/ros2ts'
import { createTransport } from '@polyhobbyist/ros2ts';
import { createFromJSON } from '@libp2p/peer-id-factory'
import type { PeerId } from '@libp2p/interface/peer-id'

// export my connection to ROS for other files to use
export let currentROSConnection : ROSConnection | undefined = undefined;


let currentUser : PeerId | undefined = undefined;

// create a simple state machine to represent which scenes are active
enum SceneState {
  Login,
  RobotSelector,
  RemoteControl,
  Map
};
      
async function RenderMain() {

    localStorage.setItem('debug', 'libp2p*')

    let currentState = SceneState.Login;

    const canvas = document.getElementById("renderCanvas") as HTMLCanvasElement; // Get the canvas element
    const engine = new BABYLON.Engine(canvas, true); // Generate the BABYLON 3D engine

    let background = new BackgroundScene();
    let backgroundScene = await background.createScene(engine, canvas);

    let login = new LoginScene();
    let loginScene = await login.createScene(engine, canvas);

    let robotSelection = new RobotSelectionScene();
    let robotSelectionScene = await robotSelection.createScene(engine, canvas);

    let remoteControl = new RemoteControlScene();
    let remoteControlScene = await remoteControl.createScene(engine, canvas);    

    login.on('authenticated', async (u : PeerId) => {
      console.log("Authenticated");
      currentState = SceneState.RobotSelector;

      currentUser = u;

      if (currentUser === undefined || currentUser === null || currentUser.multihash === undefined
        || currentUser.privateKey === undefined || currentUser.publicKey === undefined) {
        console.log("Error: User not authenticated");
        alert("Error: User not authenticated. Try refreshing the page.");
        return;
      }
      let options = {
        peerId: currentUser
      }

      createTransport("libp2p", options).then(async (transport) => {
        currentROSConnection = new ROSConnection();
        
        currentROSConnection.on('connected', () => {
          remoteControl.enter();
          currentState = SceneState.RemoteControl;
        });

        currentROSConnection.on('disconnected', () => {
          console.log("Disconnected from ROS2");
          currentROSConnection = undefined;
          remoteControl.leave();
          currentROSConnection = undefined;
          currentState = SceneState.RobotSelector;
        });

        currentROSConnection.on('error', (msg: any) => {
          console.log("Error: " + msg);
          alert("Error: " + msg);
        });

        currentROSConnection.on('onMessage', (msg: any) => {
          console.log("Message: " + msg);
        });

        currentROSConnection.connect(transport);
      });
    });

    robotSelection.on('connect', async (robotId : string) => {
      console.log("Connecting to robot: " + robotId);
      //currentState = SceneState.RemoteControl;  // switch to waiting for connection state

      if (currentROSConnection === undefined) {
        console.log("Error: ROS connection is undefined");
        alert("Error: ROS connection is undefined");
        return;
      }

      currentROSConnection.transport?.connect({multihash: robotId});

    });

    engine.runRenderLoop(function () {
      if (backgroundScene != undefined) {
        backgroundScene.render();
      }

      if (loginScene != undefined && currentState == SceneState.Login) {
        loginScene.render();
      }

      if (robotSelectionScene != undefined && currentState == SceneState.RobotSelector) {
        robotSelectionScene.render();
      }

      if (remoteControlScene != undefined && currentState == SceneState.RemoteControl) {
        remoteControlScene.render();
      }
    });

    engine.resize();

    window.addEventListener("resize", function () {
        engine.resize();
    });  
  }



  // Just like a regular webpage we need to wait for the webview
  // DOM to load before we can reference any of the HTML elements
  // or toolkit components
  window.addEventListener("load", RenderMain);